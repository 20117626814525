@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
* {
  overflow: 'hidden'
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
  background: #eeeeee;
  height: 100vh;
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}

.container {
  text-align: center;
}

.leaflet-container{
  width: 100%;
  height:72vh;
}
#components-layout-demo-top-side .logo {
    width: 120px;
    height: 31px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px 28px 16px 0;
    float: left;
}

.site-layout-background {
    background: #fff;
}
.userdashboard {
    height: 50vh;
    display: flex;
}

.userdash__userdetails {
    padding: 10px;
    flex: 0.50 1;
}

.userdetails__details {
    background: #eeeeee;
    text-align: center;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 2px 10px 20px #888888;
    display: flex;
    flex-direction: column;
}

.userdash__userdetails_2 {
    padding: 10px;
    flex: 0.5 1
}

.main-wrapper {
    box-shadow: 0px -1px 35px -10px rgba(0, 0, 0, 0.75);
}
.sensor__dash {
    color: blue;
    box-shadow: 0px -1px 35px -10px rgba(0, 0, 0, 0.75);
}
.light__dash {
    color: blue;
    box-shadow: 0px -1px 35px -10px rgba(0, 0, 0, 0.75);
}
