@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');

.App {
  text-align: center;
  background: #eeeeee;
  height: 100vh;
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}

.container {
  text-align: center;
}

.leaflet-container{
  width: 100%;
  height:72vh;
}