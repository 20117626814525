.userdashboard {
    height: 50vh;
    display: flex;
}

.userdash__userdetails {
    padding: 10px;
    flex: 0.50;
}

.userdetails__details {
    background: #eeeeee;
    text-align: center;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 2px 10px 20px #888888;
    display: flex;
    flex-direction: column;
}

.userdash__userdetails_2 {
    padding: 10px;
    flex: 0.5
}

.main-wrapper {
    box-shadow: 0px -1px 35px -10px rgba(0, 0, 0, 0.75);
}